import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import MetaComponents from "../../components/seo/MetaComponents";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import BreadcrumbList from "../../components/seo/BreadcrumbList";
import Cooperation from "../../components/Cooperation";
import HomeInfoBoxes from "../../components/HomeInfoBoxes";
import MediaLogos from "../../components/MediaLogos";

const Umschuldung = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Umschuldungskredit - Kredit umschulden mit miracl" showCalc={true} />
            <Cooperation hasGreyBg hasMarginTop />
            <HomeInfoBoxes hasMarginTop />
            <MediaLogos hasGreyBg hasMarginTop />
            <Article hasMarginTop>
                <h2>Zeiten ändern sich - dein Kredit auch!</h2>
                <p>
                    Leitzinsen niedrig, die eigene finanzielle Situation ist besser und das Jonglieren von mehreren
                    Krediten ist schon längst nervig geworden – gerade jetzt ist ein Begriff wie “Umschuldung” in vieler
                    Munde. Tatsächlich kann nicht nur ein <Link to="/artikel/wohnkredit/">Kreditvergleich</Link> sondern
                    tatsächlich eine Umschuldung bestehender Kredite schon einen deutlichen Unterschied im Monatsbudget
                    ausmachen. Aber was genau ist die Umschuldung nun wirklich? Wie funktioniert sie? Was bedeutet
                    Zinsbindung? Was für Optionen als Kreditnehmer habe ich eigentlich? Wie variabel ist mein{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinssatz
                    </Link>{" "}
                    nun wirklich? Auf was sollte man dabei achten? Antworten dazu gibt es hier – samt handfester
                    Unterstützung!
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Kreditvergleich anfordern
                </a>
                <hr />

                <p>
                    Mit einer Umschuldung ersetzt man alte Kredite mit einem neuen Kredit. Die Restschuld bleibt
                    dieselbe, aber Raten,{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinssatz
                    </Link>{" "}
                    und Zinsentwicklung können schnell bedeutende Vorteile bringen. Oft sind hier schon Ersparnisse im
                    deutlich fünfstelligen Bereich möglich!
                </p>
                <p>
                    Das beste an einer Umschuldung? Sobald die Anforderungen seitens des neuen Kreditgebers erfüllt
                    sind, fällt für Dich kaum mehr Arbeit an. Der neue Kreditgeber kommuniziert mit Deinen bisherigen
                    Geldgebern ganz eigenständig, während Du Dich zurücklehnen kannst!
                </p>
                <p>
                    Mit unseren Experten und der engen Zusammenarbeit mit mehr als 100 Banken und Kreditinstitutionen
                    österreichweit finden wir die passende Umschuldung für Dich – und das oft schon in 72 Stunden!
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Kreditvergleich anfordern
                </a>
                <hr />

                <h2>Was bedeutet Umschuldung eigentlich?</h2>
                <p>
                    Vereinfacht ausgedrückt, bedeutet Umschuldung das Ersetzen eines oder mehrerer Kredite durch einen
                    neuen{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>
                    . Die Restschuld selbst bleibt davon unberührt, vielmehr ist das Ziel, die Höhe der Rückzahlungen zu
                    senken. Dies ist gerade jetzt durch den niedrigen Leitzins sehr oft möglich, egal wie viele Gebühren
                    oder Entschädigungen eventuell dabei entstehen können.
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Kreditvergleich anfordern
                </a>
                <hr />

                <h2>Warum ist eine Umschuldung sinnvoll?</h2>
                <p>
                    Eine Umschuldung kann dann Sinn machen, wenn man mehrere Kredite oder Darlehen durch eine
                    regelmäßige Zahlung tilgen kann, anstatt viele verschiedene Arten von Zahlungen in unterschiedlichen
                    Intervallen tätigen zu müssen. Auch kann der sich ändernde Leitzinssatz durchaus Ersparnisse im
                    Vergleich zum Ursprungskredit bringen.
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Kreditvergleich anfordern
                </a>
                <hr />

                <h2>Wann zahlt sich eine Umschuldung für mich aus?</h2>
                <p>
                    Ganz einfach: Sobald die Ersparnis größer ist als die durch die Umschuldung verursachte Kosten.
                    Ersparnisse ergeben sich oft durch niedrigere Kreditzinsen, was sich oft auch direkt positiv auf
                    Ratenzahlungen auswirkt. Kosten in diesem Sinne könnten Kontoführungsgebühren oder auch
                    Entschädigungszahlungen sein. Wer sich also auf der Suche nach günstigen Krediten nur durch
                    Zinsraten locken lässt, sollte Kosten wie Vorfälligkeitsentschädigungen niemals außer Acht lassen –
                    diese Faktoren sind meist in den dazugehörigen Verträgen nachzulesen.
                </p>
                <p>
                    Dazu kommt, dass eine Umschuldung meist dann Sinn macht, wenn noch sehr Zeit bis zur{" "}
                    <Link to="/artikel/tilgung/" target="_blank" rel="noreferrer noopener">
                        Tilgung
                    </Link>{" "}
                    des Kredits vergehen kann – man spricht hier von der Restlaufzeit. In den ersten 3-5 Jahren zum
                    Beispiel werden mit den monatlichen Rückzahlungen meistens noch Nebenkosten bedient, danach stehen
                    einem sehr viele Möglichkeiten zur Umschuldung zu frei!
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Kreditvergleich anfordern
                </a>
                <hr />

                <h2>Welche Voraussetzungen gibt es bei einer Umschuldung?</h2>
                <p>
                    Zunächst einmal müssen die Rückzahlungen der bisherigen Kredite immer fristgerecht passiert sein.
                    Dazu kommt, dass die neue Finanzierung – also die Umschuldung – auch tatsächlich eine Ersparnis
                    bringt. So will es das Gesetz! Zu guter Letzt kommen natürlich bei der neuen Finanzierung auch
                    gewisse Nebenkosten dazu, mit denen man einverstanden sein muss.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kredit prüfen
                    </a>
                </p>
                <p>
                    Vorsicht: Eine Umschuldung ist nicht mit einer Zwischenfinanzierung zu verwechseln. Bei einer
                    Zwischenfinanzierung wird meist ein spezieller Kredit herangezogen, um kurzfristig fehlendes
                    Eigenkapital für wichtige Schritte in der{" "}
                    <Link to="/artikel/immobilienfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Immobilienfinanzierung
                    </Link>{" "}
                    oder{" "}
                    <Link to="/artikel/baufinanzierung/" target="_blank" rel="noreferrer noopener">
                        Baufinanzierung
                    </Link>{" "}
                    zu überbrücken.
                </p>
                <hr />

                <h2>Wie funktioniert die Umschuldung am besten?</h2>
                <p>
                    Im Grunde ist der Gedanke hinter einer Umschuldung klar. Man nimmt einen neuen{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>{" "}
                    auf, um damit bestehende Kredite zu tilgen. Der neue Kredit wird dann wie gewohnt abbezahlt – mit
                    hoffentlich niedrigeren Raten und günstigeren{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>
                    .
                </p>
                <p>
                    Dabei macht es wenig Sinn, nur zur eigenen Hausbank zu gehen. Oft fehlt die Vergleichsmöglichkeit
                    mit den Angeboten anderer Banken, fast immer ist ein persönliches Gespräch nötig und nahezu für
                    jeden Verbraucher ist der damit verbundene Zeitaufwand unrealistisch.
                </p>
                <p>
                    Gerade hier setzen wir mit miracl an. Wir bieten Dir einen Vergleich von Kreditangeboten von mehr
                    als 100 Banken in Österreich – vollkommen kostenlos! Unsere Vergleiche sind dabei stets
                    tagesaktuell. Wir verstehen unsere unabhängige, kostenlose Beratung als Dienst dem Kreditnehmer
                    gegenüber. Wir sind an keinen Dienstgeber gebunden, vergleichen stets auch Förderungen und Zuschüsse
                    der Bundesländer und streben immer danach, die besten Konditionen für Deine Umschuldung zu finden.
                    So bieten wir Dir nicht einfach einen{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinssatz
                    </Link>{" "}
                    oder eine Zinsbindung als Faustregel, sondern bereiten Dein individuelles Konzept zur Umschuldung
                    vor.
                </p>
                <p>
                    Konkret bedeutet dies, dass zunächst ein Vergleich zwischen derzeitigem und geplantem Zahlungsmodell
                    erstellt wird. Danach wird geprüft, ob die Voraussetzungen erfüllt sind und der endgültige,
                    umzuschuldende Betrag wird übermittelt. Erteilt der neue Kreditgeber nach einer Prüfung seine
                    Zusage, so wird der neue Saldo samt Zeitrahmen festgelegt.
                </p>
                <p>
                    Wir unterstützen Dich natürlich auch bei der Vorbereitung aller Anträge und anderer Formalitäten!
                    Wir prüfen auch gerne Deinen Kreditvertrag!
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Umschuldung beginnen
                </a>
                <hr />

                <h2>Was für Kosten können bei einer Umschuldung anfallen?</h2>
                <p>
                    Zunächst kann die Bank, bei welcher der bisherige Kredit läuft, eine sogenannte
                    Vorfälligkeitsentschädigung einfordern. Hier gilt es vor allem, bestehende Fristen einzuhalten.
                    Details dazu sind im Kreditvertrag zu finden. Auch bei Darlehen können noch Nebenkosten bei der
                    verfrühten{" "}
                    <Link to="/artikel/tilgung/" target="_blank" rel="noreferrer noopener">
                        Tilgung
                    </Link>{" "}
                    entstehen.
                </p>
                <p>
                    Auch Notarkosten und Bearbeitungskosten fallen beim Aufsetzen des neuen{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredits
                    </Link>{" "}
                    an. Diese Kosten müssen unbedingt bedacht werden wenn es um eine Umschuldung geht. Auch hier können
                    wir Dir zur Seite stehen und mögliche Kosten so gut es geht minimieren.
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Kreditvergleich anfordern
                </a>
                <hr />

                <h2>Bei welchen Finanzierungsarten ist eine Umschuldung möglich?</h2>
                <p>
                    Egal ob <Link to="/artikel/wohnkredit/">Wohnkredit</Link> oder{" "}
                    <Link to="/artikel/baufinanzierung/" target="_blank" rel="noreferrer noopener">
                        Baufinanzierung
                    </Link>
                    , eine Umschuldung ist grundsätzlich fast immer möglich. In den meisten Fällen scheitert dieses
                    Vorhaben nur daran, wenn Deine Bonität die Prüfung des neuen Kreditgebers nicht übersteht. Das ist
                    zum Beispiel der Fall, wenn Du bisher mit Deinen Rückzahlungen in Verzug geraten bist.
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Kreditvergleich anfordern
                </a>
                <hr />

                <h2>Wie beginne ich mit der Umschuldung?</h2>
                <p>
                    Am besten beginnst Du mit einem unabhängigen Vergleich österreichweit. Dazu brauchen wir einige
                    wenige Daten, die wir anonymisiert behandeln um die besten Konditionen für Dich zu ermitteln. Wir
                    kontaktieren Dich daraufhin so schnell wie möglich per E-Mail, um alle weiteren Schritte zu
                    besprechen.
                </p>
            </Article>
            <BreadcrumbList page={"umschuldung"}></BreadcrumbList>
        </Layout>
    );
};

export default Umschuldung;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "mortgageCalc", "page.dienstleistungen.umschuldung"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
